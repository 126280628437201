<template>
  <div class="main-page">
    <headed-block></headed-block>

    <content-page-parallax></content-page-parallax>

    <div class="content list-container mx-auto">
      <!-- filter -->
      <div class="list-container flex align-center px-5 pt-10 pb-8">
        <div class="toolbar-count mr-auto mr-md-none ml-none ml-md-auto">
          матеріалів: <span>{{ paging.total || 0 }}</span>
        </div>

        <button @click="openFilterDialog" class="toolbar-btn ml-5">
          <span class="icon filter"></span>
          <span style="margin-left: 10px">Фільтри</span>
        </button>
      </div>

      <!-- articles -->
      <div class="flex wrap justify-center px-5 px-md-0" v-if="data !== null">
        <article-card
          v-for="item in data"
          :key="item.id"
          :item="item"
          :to="getItemLink(item)"
          class="shrink-0 grow-0"
        ></article-card>

        <div ref="more" style="width: 100%">
          <div v-if="loading" class="centerize">
            <div class="preloadingif"></div>
          </div>
        </div>

        <div v-if="preload" class="preload">
          <svg
            width="95"
            height="100"
            viewBox="0 0 95 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M61.1123 11.1114C61.1123 17.2478 56.1373 22.2228 50.0009 22.2228C43.8645 22.2228 38.8895 17.2478 38.8895 11.1114C38.8895 4.97496 43.8645 0 50.0009 0C56.1373 0 61.1123 4.97496 61.1123 11.1114"
                fill="#8A8A8A"
              />
              <path
                d="M55.5567 94.445C55.5567 97.5132 53.0698 100 50.0016 100C46.9334 100 44.4465 97.5132 44.4465 94.445C44.4465 91.3768 46.9334 88.8899 50.0016 88.8899C53.0698 88.8899 55.5567 91.3768 55.5567 94.445Z"
                fill="#8A8A8A"
              />
              <path
                d="M16.6677 52.7782C16.6677 57.3811 12.9368 61.112 8.33385 61.112C3.73091 61.112 0 57.3811 0 52.7782C0 48.1752 3.73091 44.4443 8.33385 44.4443C12.9368 44.4443 16.6677 48.1752 16.6677 52.7782Z"
                fill="#8A8A8A"
              />
              <path
                d="M94.4457 52.7781C94.4457 54.3128 93.2027 55.5557 91.6682 55.5557C90.1336 55.5557 88.8906 54.3127 88.8906 52.7781C88.8906 51.2436 90.1336 50.0006 91.6682 50.0006C93.2027 50.0006 94.4457 51.2436 94.4457 52.7781Z"
                fill="#8A8A8A"
              />
              <path
                d="M80.4452 22.3589C80.9877 22.9015 80.9877 23.7812 80.4452 24.3237C79.9026 24.8653 79.0229 24.8653 78.4814 24.3237C77.9388 23.7812 77.9388 22.9015 78.4814 22.3589C79.0229 21.8164 79.9026 21.8164 80.4452 22.3589Z"
                fill="#8A8A8A"
              />
              <path
                d="M25.4475 77.3046C28.1593 80.0164 28.1593 84.4128 25.4475 87.1256C22.7357 89.8374 18.3393 89.8374 15.6265 87.1256C12.9147 84.4128 12.9147 80.0164 15.6265 77.3046C18.3393 74.5918 22.7357 74.5918 25.4475 77.3046Z"
                fill="#8A8A8A"
              />
              <path
                d="M27.4391 16.4413C31.2359 20.2382 31.2359 26.3939 27.4391 30.1908C23.6422 33.9877 17.4864 33.9877 13.6895 30.1908C9.89264 26.3939 9.89264 20.2382 13.6895 16.4413C17.4864 12.6444 23.6422 12.6444 27.4391 16.4413Z"
                fill="#8A8A8A"
              />
              <path
                d="M82.3821 79.2936C84.0097 80.9212 84.0097 83.5592 82.3821 85.186C80.7555 86.8136 78.1175 86.8136 76.4897 85.186C74.8621 83.5593 74.8621 80.9213 76.4897 79.2936C78.1174 77.6669 80.7554 77.6669 82.3821 79.2936Z"
                fill="#8A8A8A"
              />
            </g>
          </svg>
        </div>
      </div>

      <!-- non-item -->
      <div
        class="non-item my-auto flex flex-center"
        v-if="!data || !data.length"
      >
        <img src="../assets/noitem.svg" alt="noitems image" />

        <div class="ml-8">
          Вибачте, по заданим параметрам немає контенту.<br />
          Спробуйте встановити інші параметри пошуку.
        </div>
      </div>

      <subscribe-block></subscribe-block>
    </div>

    <footer-block></footer-block>

    <preloader v-show="preload"></preloader>

    <article-filter-dialog
      v-show="showFilter"
      @updateFilter="onFilterUpdate"
      @close="closeFilterDialog"
    ></article-filter-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import ArticleCard from "../components/acticle-card.vue";
import ArticleFilterDialog from "../components/article-filter-dialog.vue";

export default {
  name: "page",

  components: {
    ArticleCard,
    ArticleFilterDialog,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("Article", {
      data: "items",
      page: "page",
      filters: "filtersMap",
      paging: "paging",
      preload: "preload",
      loading: "loading",
      categoryList: "categoryList",
      currentCategory: "currentCategory",
      showFilter: "showFilter",
    }),

    ...mapGetters("Spec", ["currentSpec"]),
  },

  methods: {
    ...mapActions("Article", [
      "initRefElement",
      "fetchMenu",
      "initFetch",
      "updateCurrentSpec",
    ]),

    getItemLink(item) {
      const { params } = this.$route;

      return {
        name: "article-old",
        params: { ...params, specSlug: this.currentSpec.slug, id: item.slug },
      };
    },

    onFilterUpdate(filter) {
      this.initFetch(filter);
    },

    openFilterDialog() {
      this.$store.commit("Article/setShowFilter", true);
      if (window.innerWidth <= 768) {
        document.querySelector("body").style.overflow = "hidden";
      }
    },

    closeFilterDialog() {
      this.$store.commit("Article/setShowFilter", false);
      document.querySelector("body").style.overflow = "auto";
    },
  },

  watch: {
    "$route.params"(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateCurrentSpec();
        this.fetchMenu();
      }
    },
  },

  mounted() {
    this.initRefElement(this.$refs.more);
    this.fetchMenu(this.$store.state.Article.filter);

    document.querySelector('meta[property="og:image"]').content =
      this.$root.globalUrl + "/assets/img/teva_logo_for_site.jpg";
  },
};
</script>

<style scoped lang="scss">
@import "../css/font-noto.css";
@import "../css/variables";

$font: $fontSecondary;
$maxW: 1128px;

.list-container {
  margin: 0 auto;
  width: 100%;
  max-width: $maxW;
}

.content {
  position: relative;
  z-index: 10;

  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: calc(100vh - 64px - 94px);
}

span.icon {
  width: 20px;
  height: 20px;

  &.filter {
    background-image: url("../assets/icon/filter.svg");
  }
}

svg {
  vertical-align: bottom;
}

.centerize {
  padding: 36px;
  text-align: center;
}

.preloadingif {
  width: 60px;
  height: 60px;
  position: relative;
  display: inline-block;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid transparent;
    border-top-color: #2ab65a;
    border-radius: 100%;
    box-sizing: border-box;
    animation: rotate linear 1.5s infinite;
  }

  &::after {
    width: 66%;
    height: 66%;
    top: 17%;
    left: 17%;
    animation-duration: 1.2s;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.preload {
  padding: 12px;
  width: 100%;
  text-align: center;
  overflow: hidden;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  svg {
    animation: rotate 2s linear infinite;
  }
}

.toolbar-count {
  font-family: $fontSecondary;
  font-weight: 300;
  font-size: 12px;
  color: #204e77;

  @media (min-width: $md) {
    margin-right: 22px;

    font-weight: 400;
    font-size: 14px;
  }

  span {
    font-size: 14px;
    font-weight: 400;

    @media (min-width: $md) {
      font-size: 18px;
    }
  }
}

.toolbar-btn {
  padding: 4px 12px;
  border-radius: 66px;
  border: none;

  display: flex;
  align-items: center;
  cursor: pointer;

  background: #aa198b;
  color: #fff;

  font-family: "Noto Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  @media (min-width: $md) {
    padding: 6px 24px;

    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  svg {
    margin-right: 12px;

    width: 12px;

    @media (min-width: $md) {
      width: 20px;
    }
  }
}

.main-page {
  padding-top: 50px + 36px;
  min-height: 100vh;

  @media (min-width: $md) {
    padding-top: 64px + 35px;
  }

  @media (min-width: $lg) {
    padding-top: 64px;
  }
}

.non-item {
  font-family: $font;
  font-size: 16px;
  color: #545454;
}
</style>
