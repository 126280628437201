<template>
  <div class="filter-dialog" @click="close">
    <div class="dialog" @click.stop>
      <div class="dialog-header">
        <div class="title">Фільтри</div>

        <div class="ml-auto">
          <button @click="close" class="btn flat pa-2">
            <span class="icon close"></span>
          </button>
        </div>
      </div>

      <div class="dialog-conent">
        <div class="block">
          <div class="subtitle">Теми:</div>

          <div class="row">
            <div class="col">
              <v-checkbox
                v-model="topicAll"
                label="Всі"
                @change="toggleAll('topic', $event)"
              ></v-checkbox>
            </div>

            <div class="col" v-for="el in topicList" :key="el.key">
              <v-checkbox
                v-model="topic"
                :label="el.filterName"
                :value="el.id"
                :disabled="el.disabled"
                @change="toggleOne('topic', $event.includes(el.id))"
              ></v-checkbox>
            </div>
          </div>
        </div>

        <div class="block">
          <div class="subtitle">Препарати:</div>

          <div class="row">
            <div class="col">
              <v-checkbox
                v-model="drugAll"
                label="Всі"
                @change="toggleAll('drug', $event)"
              ></v-checkbox>
            </div>

            <div class="col" v-for="el in drugList" :key="el.key">
              <v-checkbox
                v-model="drug"
                :label="el.filterName"
                :value="el.id"
                :disabled="el.disabled"
                @change="toggleOne('drug', $event.includes(el.id))"
              ></v-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div class="dialog-actions">
        <button class="btn primary" @click="updateFilter">
          {{ count }} результатів
        </button>
        <button class="btn second outline" @click="clear">Скинути</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { debounce, get } from "lodash";

const getReactive = (from, list) =>
  list.reduce((r, key) => {
    r[key] = {
      get() {
        return get(this[from], key, []);
      },
      set(v) {
        this.$store.commit("Article/setFilter", { ...this[from], [key]: v });
        this.fetchCountAll();
      },
    };
    return r;
  }, {});

export default {
  data() {
    return {
      count: 0,

      topicAll: false,
      drugAll: false,
    };
  },

  computed: {
    ...mapState("Article", ["filter", "filterMap", "currentCategory"]),
    ...mapGetters("Article", ["filterString"]),
    ...mapGetters("Spec", ["currentSpec"]),
    
    ...getReactive("filter", ["category", "topic", "drug"]),

    spec() {
      return get(this.currentSpec, "id");
    },

    topicList() {
      return (this.filterMap.topics || []).filter(({ disabled }) => !disabled);
    },

    drugList() {
      return (this.filterMap.drugs || []).filter(
        ({ filterName, disabled }) => !disabled && !!filterName
      );
    },
  },

  methods: {
    ...mapActions('Article', ['fetchFilters']),

    toggleOne(s, v) {
      if (!v) this[`${s}All`] = false;
    },

    toggleAll(s, v) {
      if (!v) this[s] = [];
      else
        this[s] = this[`${s}List`].reduce((r, el) => {
          if (!el.disabled) r.push(el.id);
          return r;
        }, []);
    },

    fetchCountAll: debounce(async function () {
      if (!this.spec) return;
      this.count = await this.$api.fetchCount({
        spec: this.spec,
        ...this.filterString,
      });
    }, 500),

    clear() {
      this.$store.commit("Article/setFilter", {});
    },

    close() {
      this.$emit("close");
    },

    updateFilter() {
      this.$emit("updateFilter", this.filter);
      this.close();
    },
  },

  watch: {
    spec() {
      this.fetchCountAll();
    },

    filterString() {
      this.fetchCountAll();
    },

    '$route.params'() {
      this.close();
      this.fetchFilters();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../css/font-noto.css";
@import "../css/variables";
@import "../css/global";

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20%);
  opacity: 0;
}

.title {
  font-family: $fontSecondary;
  font-weight: 500;
  font-size: 16px;
  color: #204e77;

  @media (min-width: $md + 1px) {
    font-size: 26px;
  }
}

.subtitle {
  font-family: $fontSecondary;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #317575;
}

.icon {
  display: inline-block;
  width: 15px;
  height: 15px;

  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;

  @media (min-width: $md + 1px) {
    width: 20px;
    height: 20px;
  }

  &.close {
    background-image: url("../assets/icon/close.svg");
  }
}

.filter-dialog {
  position: fixed;
  z-index: 999;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media (min-width: $md + 1px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  background-color: rgba(84, 84, 84, 0.6);

  .dialog {
    padding: 0;
    width: 100%;
    color: #545454;
    background-color: #fff;
    box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.3);

    @media (min-width: $md + 1px) {
      width: 840px;
    }
  }

  .dialog-header {
    display: flex;
    align-items: center;

    padding: 18px 20px;
    height: 72px;

    @media (min-width: $md + 1px) {
      padding: 24px 32px;
      height: 84px;
    }
  }

  .dialog-conent {
    padding: 0 20px;
    max-height: calc(100vh - 72px - 72px);
    overflow: auto;

    @media (min-width: $md + 1) {
      padding: 0 32px;
      max-height: calc(100vh - 84px - 72px - 24px);
    }
  }

  .dialog-actions {
    padding: 12px 20px 18px;
    height: 72px;

    & .btn {
      margin-right: 14px;

      @media (min-width: $md + 1px) {
        margin-right: 24px;
      }
    }

    & .btn:last-child {
      margin-right: 0;
    }
  }

  .block {
    margin-bottom: 24px;

    .subtitle {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #317575;

    max-height: calc(
      calc(var(--vh, 1vh) * 100 - 72px - 72px) / 2 - 42px - 24px
    );
    overflow: auto;

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background: #317575;
    }

    &::-webkit-scrollbar-corner {
      background: none;
    }
  }

  .col {
    // padding: 5px;
    padding-bottom: 18px;
    padding-left: 0;
    width: 100%;

    font-family: $fontSecondary;
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;

    @media (min-width: $md + 1px) {
      width: 25%;
    }

    input {
      margin-right: 12px;
    }
  }
}
</style>
