<template>
  <router-link
    :to="to"
    class="article-item"
    :class="[
      { 'two-section': item.relevance === 'big' },
      { 'one-section': item.relevance === 'normal' },
      item.color,
    ]"
  >
    <div :class="`label color${item.label.id}`">
      <div class="icon"></div>
    </div>

    <picture class="article-img">
      <source
        :srcset="`${$root.globalUrl}/uploads/title/${item.titlePicture}`"
        media="(min-width: 540px)"
      />

      <img
        :src="`${$root.globalUrl}/uploads/title/${item.titlePictureMobile}`"
        alt=""
      />
    </picture>

    <div class="description">
      <div class="text-wrapper">
        <p class="title" v-html="item.shortTitle"></p>
        <p class="subtitle" v-html="item.shortDescription"></p>
      </div>

      <div class="more-btn">Детальніше</div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    item: Object,
    to: [String, Object],
  },
};
</script>

<style scoped lang="scss">
@import "../css/variables";

$font: $fontSecondary;

$maxWidth: 1110px;
$cardPadding: 12px;

.article-item {
  position: relative;

  margin: 10px 10px;
  width: 100%;

  text-decoration: none;
  color: inherit;

  &:hover .more-btn {
    background: #00a03b !important;
    color: white !important;
  }

  &.last-block {
    min-height: 282px;
    background: #f1f1f1;
    max-width: 262px;
    width: 100%;
    position: relative;
    padding-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $sm) {
      display: none;
    }

    .next-page {
      cursor: pointer;

      * {
        transition: all 0.2s ease;
      }

      &:hover {
        circle {
          fill: white;
        }

        path {
          stroke: #7fc25d;
        }
      }
    }

    .icon {
      margin-bottom: 10px;
    }

    .next-text {
      color: #545454;
      font-family: $font;
      font-size: 14px;
    }
  }

  .label {
    width: 26px;
    height: 36px;
    position: absolute;
    right: 15px;
    top: 0;
    border-radius: 0 0 50px 50px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 7px;

    .icon {
      width: 12px;
      height: 12px;
    }

    &.color1 {
      background: #aa198b;

      .icon {
        background: url("../assets/img/page/label-instrument.png") no-repeat
          center;
        background-size: contain;
      }
    }

    &.color2 {
      background: #b3d06b;

      .icon {
        background: url("../assets/img/page/label-useful.png") no-repeat center;
        background-size: contain;
      }
    }

    &.color3 {
      background: #fb3449;

      .icon {
        background: url("../assets/img/page/label-interesting.png") no-repeat
          center;
        background-size: contain;
      }
    }

    &.color4 {
      background: #005a85;

      .icon {
        background: url("../assets/img/page/label-event.png") no-repeat center;
        background-size: contain;
      }
    }

    &.color5 {
      background: #9e9e9e;

      .icon {
        background: url("../assets/img/page/lablel-past.png") no-repeat center;
        background-size: contain;
      }
    }

    &.color6 {
      background: #00a03b;

      .icon {
        background: url("../assets/img/page/lablel-active.png") no-repeat center;
        background-size: contain;
      }
    }

    &.color7 {
      background: #005a85;

      .icon {
        background: url("../assets/img/page/lablel-future.png") no-repeat center;
        background-size: contain;
      }
    }

    &.color8 {
      background: #fb3449;

      .icon {
        background: url("../assets/img/page/lablel-end.png") no-repeat center;
        background-size: contain;
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

.article-img {
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;

  flex-shrink: 0;

  img {
    width: 100%;
    min-height: 100%;
  }
}

.description {
  padding-bottom: 20px;
  width: 100%;
  flex-shrink: 0;
}

.text-wrapper {
  padding: 16px 22px;
  width: 100%;
  max-height: 87px;
  overflow: hidden;
}

.title,
.subtitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  font-family: $font;
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.subtitle {
  margin-top: 5px;

  font-family: $font;
  font-size: 11px;
  font-weight: 400;
  color: white;
}

.more-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;

  margin: auto 0 0 auto;
  padding: 6px 10px;

  max-width: 80px;

  background: #f5f5f5;
  border-radius: 50px 0 0 50px;
  transition: all 0.2s ease;
  // user-select: none;

  font-family: $font;
  font-size: 9px;
  font-weight: 400;
  color: #00a03b;
}

.one-section {
  @media (min-width: $sm) {
    width: 262px;
    height: 302px;

    .article-img {
      height: 168px;
    }

    .description {
      display: flex;
      flex-direction: column;

      height: calc(302px - 168px);
    }
  }

  &.color {
    &1 {
      background: #00a03b;
    }

    &2 {
      background: #379c78;
    }

    &3 {
      background: #005a85;
    }
  }

  &.disColor {
    &1 {
      background: #aeaeae;
    }

    &2 {
      background: #00a03b;
    }

    &3 {
      background: #005a85;
    }

    &4 {
      background: #fb3449;
    }
  }
}

.two-section {
  @media (min-width: $sm) {
    display: flex;
    width: 544px;
    height: 302px;

    .article-img {
      width: 284px;
      height: 302px;
    }

    .description {
      display: flex;
      flex-direction: column;

      width: calc(544px - 284px);
      height: 302px;
    }

    .text-wrapper {
      padding-top: 50px;
      padding-right: 50px;
    }
  }

  &.color {
    background-size: 50%;

    &1 {
      background: #317575 url("../assets/img/page/pattern1.png");

      @media screen and (max-width: 572px) {
        background: #317575;
      }
    }

    &2 {
      background: #379c78 url("../assets/img/page/pattern2.png") no-repeat right
        top;

      @media screen and (max-width: 572px) {
        background: #379c78;
      }
    }

    &3 {
      background: #005a85 url("../assets/img/page/pattern1.png");

      @media screen and (max-width: 572px) {
        background: #317575;
      }
    }
  }

  &.disColor {
    background-size: 50%;

    &1 {
      background: url("../assets/img/page/pattern1.png") #005a85;
      @media screen and (max-width: 572px) {
        background: #317575;
      }
    }

    &2 {
      background: url("../assets/img/page/pattern2.png") #aeaeae no-repeat right
        top;
      @media screen and (max-width: 572px) {
        background: #379c78;
      }
    }

    &3 {
      background: url("../assets/img/page/pattern1.png") #00a03b;
      @media screen and (max-width: 572px) {
        background: #317575;
      }
    }

    &4 {
      background: url("../assets/img/page/pattern2.png") #fb3449;

      @media screen and (max-width: 572px) {
        background: #317575;
      }
    }
  }
}
</style>
